import React from 'react'
import Helmet from 'react-helmet'
import PageHeader from 'components/PageHeader'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Paragraph } from 'components/Typography'
import styles from './index.module.less'
import image1 from 'images/project-10a.png'
import image2 from 'images/project-10b.png'
import image3 from 'images/project-10c.png'
import Layout from 'components/Layout'

const Page = () => (
  <Layout>
    <Helmet
      title="Whānau lend a helping hand"
      meta={[
        {
          name: 'description',
          content:
            'Dealing with the demands of a growing family and a home in need of critical repair, this local Stokes Valley family reached out to the Ryhaven Trust for urgent support.',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>
    <PageHeader
      title="Whānau lend a helping hand"
      intro="Dealing with the demands of a growing family and a home in need of critical repair, this local Stokes Valley family reached out to the Ryhaven Trust for urgent support."
    />
    <Grid fluid>
      <Row>
        <Col sm={8} className={styles.projectText}>
          <Paragraph>
            The kitchen, bathroom and laundry were at the end of their useful
            life and badly in need of renovation.
          </Paragraph>
          <Paragraph>
            The trust was able to step in and give the kitchen a makeover,
            installing units, tops and floor coverings. The family welcomed a
            new shower, vanity and floor coverings in the bathroom and laundry.
            There was an upgrade to the plumbing and new fans were installed in
            the kitchen and bathroom.
          </Paragraph>
          <Paragraph>
            After water-blasting the exterior walls, the family prepared the
            surfaces for painting. Paul from Ryhaven taught the teenage children
            how to paint, and supervised by Paul, they have managed to do a
            terrific job.
          </Paragraph>
          <Paragraph>
            The whānau plan to carry on painting work over the summer months.
          </Paragraph>
          <Paragraph>
            Thank you to{' '}
            <a href="https://www.geeves.co.nz">Geeves Scaffolding</a> and{' '}
            <a href="https://www.dulux.co.nz">Dulux</a> for providing resources
            for the project.
          </Paragraph>
        </Col>
        <Col sm={4} className={styles.projectImages}>
          <img className={styles.image} src={image1} alt="" />
          <img className={styles.image} src={image2} alt="" />
          <img className={styles.image} src={image3} alt="" />
        </Col>
      </Row>
    </Grid>
  </Layout>
)

export default Page
